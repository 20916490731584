/*                                       */
var objectProto = Object.prototype;

/**
 *
 *
 *
 */
var nativeObjectToString = objectProto.toString;

/**
 *
 *
 *
 *
 *
 */
function objectToString(value) {
  return nativeObjectToString.call(value);
}

export default objectToString;
