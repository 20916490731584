import {observeItem} from "./mouseSpeedTracker";
import {isTouchDevice} from "./utils";
import {
    FLYOUT_TRACKING_EVENTS,
    menuLinkTracking,
    navigateUpTracking,
    openAndNavigateDownTracking,
    trackFlyoutCloseImmediately,
    trackFlyoutDebounced,
    trackFlyoutExitImmediately
} from "./tracking";

export class DesktopTopLevelItem extends HTMLElement {

    static get customElement() {
        return "nav-desktop-top-level-item";
    }

    constructor() {
        super();
        this.psr = this.dataset.psr;
    }

    connectedCallback() {
        if(!this.shadowRoot) {
            this.shadow = this.attachShadow({mode: "open"});

            const buttonSlot = document.createElement('slot');
            buttonSlot.setAttribute("name", "button");
            this.shadow.appendChild(buttonSlot);

            this.flyout = document.createElement(Flyout.customElement);
            this.flyout.part = "navFlyout";
            this.shadow.appendChild(this.flyout);
            const tab = document.createElement("span");
            tab.className = "nav_desktop-global-navigation-item__tab";
            tab.textContent = this.querySelector('.nav_desktop-global-navigation-item__title')?.textContent;
            this.querySelector('.nav_desktop-global-navigation-item')?.appendChild(tab);
        }

        const buttonLink = this.querySelector('a[href]');
        if (buttonLink) {
            if (isTouchDevice()) {
                buttonLink.addEventListener("click", event => {
                    if (!this.flyout.isVisible()) {
                        event.preventDefault();
                        document.querySelectorAll(DesktopTopLevelItem.customElement).forEach(topLevelItem => topLevelItem.hideFlyout());
                        this.initializeAndShowFlyout();
                        this.trackOpenFlyoutAndNavigateDown(this);
                    } else {
                        menuLinkTracking(event.target);
                    }
                });
            } else {
                observeItem(
                    this,
                    () => {
                        this.initializeAndShowFlyout();
                        this.trackOpenFlyoutAndNavigateDown(this);
                    },
                    () => {
                        this.hideFlyout();
                        trackFlyoutDebounced(FLYOUT_TRACKING_EVENTS.EXIT, this);
                    },
                    {
                        LIVE_SELECTOR: DesktopTopLevelItem.customElement,
                        SPEED_LIMIT: 0,
                        IDLE: 150,
                        FALLBACK: 500,
                        CHECK_FROM_ELEMENT: true
                    });
                buttonLink.addEventListener("click", event => menuLinkTracking(event.target));
            }
        }
    }

    trackOpenFlyoutAndNavigateDown = (navigateDownElement) => openAndNavigateDownTracking(navigateDownElement, this);

    initializeAndShowFlyout() {
        this.flyout.initializeCloseCallbacks(this.setInactive, () => trackFlyoutCloseImmediately(this));
        this.flyout.initializeNavigateDownTrackingCallback(this.trackOpenFlyoutAndNavigateDown);
        this.flyout.showContent(this.psr, this.setActive);
    }

    hideFlyout() {
        this.flyout.hide();
        this.setInactive();
        window.o_global.eventQBus.emit("ftnav.global-navigation.closed");
    }

    exitFlyoutWithTracking() {
        trackFlyoutDebounced.cancel();
        this.hideFlyout();
        trackFlyoutExitImmediately(this);
    }

    activeClass = 'nav_desktop-global-navigation-item--active';
    isActive = () => this.querySelector('.nav_desktop-global-navigation-item')
        .classList.contains(this.activeClass);
    setInactive = () => this.querySelector('.nav_desktop-global-navigation-item')
        .classList.remove(this.activeClass);
    setActive = () => this.querySelector('.nav_desktop-global-navigation-item')
        .classList.add(this.activeClass);
}

/**
 *
 *
 *
 */
export class Flyout extends HTMLElement {

    static get customElement() {
        return "nav-flyout";
    }

    constructor() {
        super();
    }

    connectedCallback() {
        if(!this.panel) {
            this.panel = document.createElement("div");
            this.panel.className = "nav_flyout-panel";
            this.panel.part = "navFlyoutPanel";
            this.shadow = this.panel.shadowRoot || this.panel.attachShadow({mode: "open"});
            this.appendChild(this.panel);
        }
        this.hide();
    }

    psrSelector = (psrString) => `${FlyoutContent.customElement}[data-psr="${psrString}"]`;

    _displayLoadedContent(psrString, setActive) {
        const elementToShow = this.shadow.querySelector(this.psrSelector(psrString));
        if (elementToShow) {
            const visibleElement = this.shadow.querySelector('[data-visible="true"]');
            const oldHeight = visibleElement ? visibleElement.offsetHeight : 0; /*                                       */
            this.shadow.querySelectorAll('[data-visible="true"]').forEach(element => element.setAttribute("data-visible", "false"));

            elementToShow.dataset.visible = "true"; /*                                    */
            /*                                                                                                          */
            requestAnimationFrame(() => {
                const newHeight = elementToShow.offsetHeight;
                this.style.paddingBottom = (newHeight < oldHeight) ? `${oldHeight - newHeight}px` : "0";
            });
            /*                                                    */
            if (this.style.display !== "block") {
                const loadStylesInterval = setInterval(() => {
                    if (elementToShow.stylesLoaded()) {
                        this.style.display = "block";
                        if (setActive) setActive();
                        clearInterval(loadStylesInterval);
                    }
                }, 10);
            }
        }
    }

    _createFlyoutContent(html, psr, parentPsr) {
        const flyoutContent = document.createElement(FlyoutContent.customElement);
        flyoutContent.dataset.psr = psr;
        flyoutContent.dataset.visible = "false";
        if (parentPsr) flyoutContent.dataset.parentPsr = parentPsr;
        const flyoutContentShadow = flyoutContent.attachShadow({mode: "open"});
        flyoutContentShadow.innerHTML = html;
        this.shadow.appendChild(flyoutContent);
        flyoutContent.initializeFlyoutNavigation(this.showContent, () => {
            this.hide();
            this.trackCloseButton();
        }, this.navigateDownTracking);
        return flyoutContent;
    }

    showContent = (psrString, setActive) => {
        if (!this.shadow.querySelector(this.psrSelector(psrString))) {
            fetch(`/nav-chekov/menu-fragment/desktop/${psrString}`)
                .then(response => response.text())
                .then(html => this._createFlyoutContent(html, psrString, this.shadow.querySelector('[data-visible="true"]')?.dataset.psr))
                .then(() => setTimeout(() => this._displayLoadedContent(psrString, setActive))); /*                                                      */
        } else {
            this._displayLoadedContent(psrString, setActive);
        }
    }

    initializeCloseCallbacks = (closeParentCallback, closeButtonTrackingCallback) => {
        this.closeCallbackFromParent = closeParentCallback;
        this.trackCloseButton = closeButtonTrackingCallback;
    }

    initializeNavigateDownTrackingCallback = (navigateDownTrackingCallback) => {
        this.navigateDownTracking = navigateDownTrackingCallback;
    }

    isVisible() {
        return this.style.display !== "none";
    }

    hide = () => {
        if (this.closeCallbackFromParent) this.closeCallbackFromParent();
        this.style.display = "none";
        this.shadow.querySelectorAll('[data-visible="true"]')
            .forEach(element => element.setAttribute("data-visible", "false"));
    }
}

/**
 *
 *
 *
 *
 *
 *
 *
 *
 */
export class FlyoutContent extends HTMLElement {

    static get customElement() {
        return "nav-flyout-content";
    }

    static observedAttributes = ["data-visible"];

    constructor() {
        super();
        this.psr = this.dataset.psr;
    }

    connectedCallback() {
        this.dataset.visible = "false";
        this.shadowRoot.querySelectorAll('a[href]').forEach(element =>
            element.addEventListener("click", () => menuLinkTracking(element)));
    }

    initializeFlyoutNavigation(showAnotherPsrCallback, closeCallback, navigateDownTracking) {
        this.shadowRoot.querySelectorAll('[data-change-level-psr]').forEach(element =>
            element.addEventListener("click", e => {
                e.preventDefault();
                if (element.dataset.navigateUp === "true") navigateUpTracking(element);
                else navigateDownTracking(element);
                showAnotherPsrCallback(element.dataset.changeLevelPsr);
            }));
        this.shadowRoot.querySelectorAll('[data-close-flyout]').forEach(element =>
            element.addEventListener("click", e => {
                e.preventDefault();
                closeCallback();
            }));
    }

    stylesLoaded = () => this.shadowRoot && this.shadowRoot.querySelector('.nav_flyout-content') &&
        getComputedStyle(this.shadowRoot.querySelector('.nav_flyout-content'))
            .getPropertyValue("display") !== "none";

    hide() {
        this.style.display = "none";
    }

    show() {
        this.style.display = "block";
    }

    attributeChangedCallback(name, oldValue, newValue) {
        if (name === "data-visible" && oldValue !== newValue) {
            if (newValue === "true") this.show();
            else this.hide();
        }
    }
}
