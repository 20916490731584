import baseTrim from './_baseTrim.js';
import isObject from './isObject.js';
import isSymbol from './isSymbol.js';

/*                                                     */
var NAN = 0 / 0;

/*                                                       */
var reIsBadHex = /^[-+]0x[0-9a-f]+$/i;

/*                                       */
var reIsBinary = /^0b[01]+$/i;

/*                                      */
var reIsOctal = /^0o[0-7]+$/i;

/*                                                             */
var freeParseInt = parseInt;

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
function toNumber(value) {
  if (typeof value == 'number') {
    return value;
  }
  if (isSymbol(value)) {
    return NAN;
  }
  if (isObject(value)) {
    var other = typeof value.valueOf == 'function' ? value.valueOf() : value;
    value = isObject(other) ? (other + '') : other;
  }
  if (typeof value != 'string') {
    return value === 0 ? value : +value;
  }
  value = baseTrim(value);
  var isBinary = reIsBinary.test(value);
  return (isBinary || reIsOctal.test(value))
    ? freeParseInt(value.slice(2), isBinary ? 2 : 8)
    : (reIsBadHex.test(value) ? NAN : +value);
}

export default toNumber;
