/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
function isObjectLike(value) {
  return value != null && typeof value == 'object';
}

export default isObjectLike;
