export function isTouchDevice() {
    return window.o_global && window.o_global.device.isTouchable;
}

/**
 *
 *
 *
 */
export function merge(target, params) {
    let result = target;
    for (const pKey in params) {
        result[pKey] = params[pKey];
    }

    return result;
}

export function calculateTopOffset(element) {
    const topOffset = element.getBoundingClientRect().top + window.scrollX;
    return `${topOffset}px`;
}

export function initializeElement(c) {
    customElements.get(c.customElement) || customElements.define(c.customElement, c);
}