import Symbol from './_Symbol.js';

/*                                       */
var objectProto = Object.prototype;

/*                                            */
var hasOwnProperty = objectProto.hasOwnProperty;

/**
 *
 *
 *
 */
var nativeObjectToString = objectProto.toString;

/*                             */
var symToStringTag = Symbol ? Symbol.toStringTag : undefined;

/**
 *
 *
 *
 *
 *
 */
function getRawTag(value) {
  var isOwn = hasOwnProperty.call(value, symToStringTag),
      tag = value[symToStringTag];

  try {
    value[symToStringTag] = undefined;
    var unmasked = true;
  } catch (e) {}

  var result = nativeObjectToString.call(value);
  if (unmasked) {
    if (isOwn) {
      value[symToStringTag] = tag;
    } else {
      delete value[symToStringTag];
    }
  }
  return result;
}

export default getRawTag;
