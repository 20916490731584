import baseGetTag from './_baseGetTag.js';
import isObjectLike from './isObjectLike.js';

/*                                       */
var symbolTag = '[object Symbol]';

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
function isSymbol(value) {
  return typeof value == 'symbol' ||
    (isObjectLike(value) && baseGetTag(value) == symbolTag);
}

export default isSymbol;
