import Symbol from './_Symbol.js';
import getRawTag from './_getRawTag.js';
import objectToString from './_objectToString.js';

/*                                       */
var nullTag = '[object Null]',
    undefinedTag = '[object Undefined]';

/*                             */
var symToStringTag = Symbol ? Symbol.toStringTag : undefined;

/**
 *
 *
 *
 *
 *
 */
function baseGetTag(value) {
  if (value == null) {
    return value === undefined ? undefinedTag : nullTag;
  }
  return (symToStringTag && symToStringTag in Object(value))
    ? getRawTag(value)
    : objectToString(value);
}

export default baseGetTag;
