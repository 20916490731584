import {merge} from "./utils";

let speed = Number.MAX_VALUE,
    speedHistory = [],
    isInUse = false,
    timeout;

const defaultSettings = {
    SPEED_LIMIT: 240,
    IDLE: 75,
    FALLBACK: 300,
    MOUSELEAVE_DELAY: 200,
    LIVE_SELECTOR: null,
    NAMESPACE: 'mouseSpeedTracker',
    CHECK_FROM_ELEMENT: false
}

function trackMouseSpeed(event) {
    let latest = speedHistory[0],
        maxTimeFrameToMeasure = 150, /*           */
        pixelPerSecond = 0,
        distance = 0,
        measuredTimeFrame = 0,
        count = 0,
        current,
        next;

    clearTimeout(timeout);
    timeout = setTimeout(() => speed = 0, 200);

    speedHistory.unshift({
        date: new Date(),
        x: event.screenX,
        y: event.screenY
    });

    if (speedHistory.length > 50) {
        speedHistory.length = 50;
    }

    for (let i = 0, n = speedHistory.length; i < n; i++) {
        current = speedHistory[i];
        next = speedHistory[i + 1];

        if (!next || (latest.date - next.date) > maxTimeFrameToMeasure) {
            break;
        }

        distance += Math.max(Math.abs(current.x - next.x), Math.abs(current.y - next.y));
        measuredTimeFrame = latest.date - next.date;
        count++;
    }

    if (count > 0) {
        pixelPerSecond = Math.round(distance * (1000 / measuredTimeFrame));
    }

    speed = pixelPerSecond;
}

export function observeMousemove() {
    document.addEventListener("mousemove", trackMouseSpeed);
    isInUse = true;
}

export function observeItem(item, mouseenterHandler, mouseleaveHandler, userOptions) {
    let options = merge(defaultSettings, userOptions),
        mouseenterTimeout,
        mouseleaveTimeout,
        mouseleaveFunction,
        interval;

    const _onMouseEnter = function (event) {
        if (typeof event.target.matches !== "function" || !event.target.matches(options.LIVE_SELECTOR)) {
            return;
        }

        event.stopPropagation();

        const thisElement = event.target,
            appendMouseenter = function (thisElement) {
                if (!!mouseleaveFunction) {
                    mouseleaveFunction();
                    mouseleaveFunction = undefined;
                }
                thisElement.setAttribute('data-mouseentered-' + options.NAMESPACE, true);
                mouseenterHandler.apply(thisElement, [thisElement]);
            };

        clearTimeout(mouseleaveTimeout);

        const isMouseEntered = thisElement.getAttribute('data-mouseentered-' + options.NAMESPACE) === "true";

        if (!isMouseEntered && (!options.CHECK_FROM_ELEMENT || event.fromElement || event.relatedTarget)) {
            /*                                                        */
            if (speed <= options.SPEED_LIMIT) {
                appendMouseenter(thisElement);
                return;
            }

            /*                                                                                       */
            clearInterval(interval);
            interval = setInterval(function () {
                if (!speedHistory[0]) {
                    clearInterval(interval);
                    return;
                }
                const lastMouseMove = new Date() - speedHistory[0].date;
                if (lastMouseMove >= options.IDLE) {
                    appendMouseenter(thisElement);
                    clearInterval(interval);
                    clearTimeout(mouseenterTimeout);
                }
            }, options.IDLE);

            /*                                                                        */
            clearTimeout(mouseenterTimeout);
            mouseenterTimeout = setTimeout(() => {
                appendMouseenter(thisElement);
                clearInterval(interval);
            }, options.FALLBACK);
        }

        return true;
    };

    const _onMouseLeave = function (event) {
        if (typeof event.target.matches !== "function" || !event.target.matches(options.LIVE_SELECTOR)) {
            return;
        }

        event.stopPropagation();

        const thisElement = event.target;

        if (thisElement.getAttribute('data-mouseentered-' + options.NAMESPACE) === "true") {
            mouseleaveFunction = function () {
                thisElement.setAttribute('data-mouseentered-' + options.NAMESPACE, false);
                mouseleaveHandler.apply(thisElement, [item]);
            };
            mouseleaveTimeout = setTimeout(mouseleaveFunction, options.MOUSELEAVE_DELAY);
        } else {
            clearInterval(interval);
            clearTimeout(mouseenterTimeout);

            if (!!mouseleaveFunction) {
                mouseleaveTimeout = setTimeout(mouseleaveFunction, options.MOUSELEAVE_DELAY);
            }
        }
    };

    item.addEventListener('mouseenter', _onMouseEnter, true);
    item.addEventListener('mouseleave', _onMouseLeave, true);
}
