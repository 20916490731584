import debounce from "../../node_modules/lodash-es/debounce.js";
import {track} from "@otto-ec/nav_star-track/src/js/star-track";

const FLYOUT_TRACKING_DEBOUNCE_TIME_IN_MS = 1000;
const FLYOUT_STATES = {
    'OPEN': 'OPEN',
    'CLOSED': 'CLOSED'
}
export const FLYOUT_TRACKING_EVENTS = {
    'OPEN': 'FLYOUT_TRACKING_OPEN',
    'EXIT': 'FLYOUT_TRACKING_EXIT'
}

let lastTrackedFlyoutState = FLYOUT_STATES.CLOSED;

function trackFlyoutOpenImmediately(target) {
    if (lastTrackedFlyoutState !== FLYOUT_STATES.OPEN) {
        track(target, "user-action/menu-open");
    }
    lastTrackedFlyoutState = FLYOUT_STATES.OPEN;
}

export function trackFlyoutExitImmediately(target) {
    if (lastTrackedFlyoutState !== FLYOUT_STATES.CLOSED) {
        track(target, "user-action/menu-exit");
    }
    lastTrackedFlyoutState = FLYOUT_STATES.CLOSED;
}

export function trackFlyoutCloseImmediately(target) {
    if (lastTrackedFlyoutState !== FLYOUT_STATES.CLOSED) {
        track(target, "user-action/menu-close");
    }
    lastTrackedFlyoutState = FLYOUT_STATES.CLOSED;
}

export const trackFlyoutDebounced = debounce(
    (trackingEvent, target) => {
        switch (trackingEvent) {
            case FLYOUT_TRACKING_EVENTS.OPEN:
                trackFlyoutOpenImmediately(target);
                break;
            case FLYOUT_TRACKING_EVENTS.EXIT:
                trackFlyoutExitImmediately(target);
                break;
        }
    },
    FLYOUT_TRACKING_DEBOUNCE_TIME_IN_MS,
    {'leading': false, 'trailing': true}
);

export function menuLinkTracking(linkElement) {
    track(linkElement, "user-action/visit-menu-link");
}

export function navigateUpTracking(backElement) {
    track(backElement, "user-action/menu-navigate-up", (commands) => {
        commands[0].dataContainer["nav_GlobalNavigationFeature"] = "standard";
        return commands;
    });
}

export function openAndNavigateDownTracking(downLink, navigation) {
    /*                                                      */
    trackFlyoutDebounced(FLYOUT_TRACKING_EVENTS.OPEN, navigation);
    /*                                                             */
    trackFlyoutOpenImmediately(navigation);
    track(downLink, "user-action/menu-navigate-down", commands => {
        commands[0].dataContainer["nav_GlobalNavigationFeature"] = "standard";
        return commands;
    });
}