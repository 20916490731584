import trimmedEndIndex from './_trimmedEndIndex.js';

/*                                    */
var reTrimStart = /^\s+/;

/**
 *
 *
 *
 *
 *
 */
function baseTrim(string) {
  return string
    ? string.slice(0, trimmedEndIndex(string) + 1).replace(reTrimStart, '')
    : string;
}

export default baseTrim;
