import {DesktopTopLevelItem, Flyout, FlyoutContent} from "./desktopElements";
import {TopBrands} from "./topBrands";
import {observeMousemove} from "./mouseSpeedTracker";
import {isTouchDevice, initializeElement} from "./utils";

initializeElement(DesktopTopLevelItem);
initializeElement(Flyout);
initializeElement(FlyoutContent);
initializeElement(TopBrands);

/*                                                                                 */
/*                   */
document.addEventListener("click", event => {
    if (!event.target.closest(".nav_desktop-global-navigation")) {
        event.target.querySelectorAll(DesktopTopLevelItem.customElement).forEach(
            (topLevelItem) => {
                if (topLevelItem.isActive()) topLevelItem.exitFlyoutWithTracking();
            }
        );
    }
});

if (!isTouchDevice()) observeMousemove();
