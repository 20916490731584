/*                                               */
var reWhitespace = /\s/;

/**
 *
 *
 *
 *
 *
 *
 */
function trimmedEndIndex(string) {
  var index = string.length;

  while (index-- && reWhitespace.test(string.charAt(index))) {}
  return index;
}

export default trimmedEndIndex;
