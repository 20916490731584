import {track} from "@otto-ec/nav_star-track/src/js/star-track";

export class TopBrands extends HTMLElement {
    static get customElement() {
        return "nav_top-brands";
    }

    constructor() {
        super();
    }

    connectedCallback() {
        Array.from(this.getElementsByClassName('nav_top-brands__card'))
            .forEach(entry => {
                entry.addEventListener('click', event => {
                    track(event.target, "user-action/visit-top-brands-link");
                })
            });
    }
}